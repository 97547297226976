import React from "react";

type Props = {
  children: JSX.Element;
};

const FiltersWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className="h-full bg-white rounded-[20px] pl-7 pt-6">
      <span className="font-sf-pro-bold text-h3">Filtry</span>
      {children}
    </div>
  );
};

export default FiltersWrapper;
