import React from "react";
import { useNavigate } from "react-router-dom";

import TableRow from "src/components/ui/TableRow";

import { getSubscriptionEditPath } from "src/common/router/routerPaths";

import { formatPrice } from "src/utils/currency";

import EditIcon from "src/assets/images/edit.svg";
import DeleteIcon from "src/assets/images/delete.svg";

type Props = {
  data: any;
  onClick?: () => void;
};

const SubscriptionTableRow: React.FC<Props> = ({
  data: { _id, name, description, price, duration, isDemo, isActive },
  onClick,
}) => {
  const navigate = useNavigate();

  const Pill = (title: string, bgColor: string, titleColor: string) => {
    return (
      <div className={`px-3 h-[33px] rounded-[36px] ${bgColor} flex justify-center items-center`}>
        <span className={`${titleColor} text-h6`}> {title}</span>
      </div>
    );
  };

  return (
    <TableRow onClick={onClick}>
      <td className="flex flex-col ml-5 mt-2">
        <span className="text-h5 font-sf-pro-regular ">{name}</span>
        <div className="mt-2 mb-2 flex flex-row space-x-2">
          {isActive ? Pill("Aktywna", "bg-light-green", "text-green") : Pill("Nieaktywna", "bg-light-red", "text-red")}
          {isDemo ? Pill("Demo", "bg-light-blue", "text-blue") : undefined}
        </div>
      </td>
      <td className="max-w-[15rem] whitespace-normal">
        <span className="font-sf-pro-regular text-h5">{description}</span>
      </td>
      <td>
        <span className="font-sf-pro-regular text-text-secondary">{formatPrice(price, "zł")}</span>
      </td>
      <td>
        <span className="font-sf-pro-regular">{`${duration} dni`}</span>
      </td>
      <td>
        <div className="flex justify-center items-center cursor-pointer">
          <img src={EditIcon} onClick={() => navigate(getSubscriptionEditPath({ subscriptionId: _id }))} />
          <img src={DeleteIcon} />
        </div>
      </td>
    </TableRow>
  );
};

export default SubscriptionTableRow;
