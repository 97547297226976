import { boolean, number, object, string } from "yup";

export default object({
  name: string().required("Wartość jest wymagana"),
  price: number().typeError("Niepoprawna wartość").required("Wartość jest wymagana"),
  description: string().required("Wartość jest wymagana"),
  duration: number().typeError("Niepoprawna wartość").required("Wartość jest wymagana"),
  isDemo: boolean(),
  isActive: boolean(),
}).required();
