import { useUsersQuery } from "src/api/graphql/generated/gql-hooks";
import { GetUsersOptionsInput } from "src/api/graphql/types-and-hooks";
import { useToastError } from "src/common/hooks/useToast";

export const useUsers = (options: GetUsersOptionsInput) => {
  const { loading, data } = useUsersQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const users = data?.users?.documents || [];
  const total = data?.users?.total;

  return {
    loading,
    users,
    total,
  };
};
