import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from "src/components/ui/Table";
import Pagination from "src/components/ui/Pagination";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleContentContainer from "src/components/ui/ModuleContentContainer";

import { getCompaniesPath, getCompanyDetailsPath } from "src/common/router/routerPaths";
import { GetCompaniesOptionsInput } from "src/api/graphql/types-and-hooks";

import CompanyFilters from "./CompanyFilters";
import CompanyTableRow from "./CompanyTableRow";
import { useCompanies } from "./company.hooks";

const HEADERS = ["Nazwa", "NIP", "Subskrypcja od", "Subskrypcja do", "Cena"];

const Companies = () => {
  const navigate = useNavigate();
  const [filteringData, setFilteringData] = useState<GetCompaniesOptionsInput>({ take: 5, skip: 0, filter: {} });
  const { companies, total } = useCompanies(filteringData);

  const renderRows = () => {
    if (!companies) return null;
    return companies.map((d, i) => {
      return (
        <CompanyTableRow data={d} key={d._id} onClick={() => navigate(getCompanyDetailsPath({ companyId: d._id }))} />
      );
    });
  };

  return (
    <>
      <ModuleHeader title="Firmy" />
      <ModuleContentContainer
        filters={
          <CompanyFilters
            onChange={(filters) =>
              setFilteringData((prevState) => {
                return { ...prevState, filter: filters };
              })
            }
          />
        }
        title="Lista firm"
      >
        <Table headers={HEADERS} isLoading={false} rows={renderRows()} />
        <Pagination
          total={total}
          filteringData={filteringData}
          route={getCompaniesPath()}
          onChange={setFilteringData}
        />
      </ModuleContentContainer>
    </>
  );
};

export default Companies;
