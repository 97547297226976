import React, { useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import Table from "src/components/ui/Table";
import Confirm from "src/components/ui/Confirm";

import { SortOptions, User, UserRoles } from "src/api/graphql/types-and-hooks";

import DeleteIcon from "src/assets/images/delete.svg";

import { getOrderDetailsPath } from "src/common/router/routerPaths";

import { useCompany, useDeleteUserFromCompany } from "./company.hooks";
import CompanyOrderTableRow from "./CompanyOrderTableRow";

import { useOrders } from "../order/order.hooks";

type UserComponentProps = {
  user: Partial<User>;
};

const CompanyDetails = () => {
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [userToDeleteId, setUserToDeleteId] = useState<string | null>(null);
  const { companyId } = useParams();
  const { company } = useCompany(companyId);
  const { orders } = useOrders({
    take: 5,
    skip: 0,
    filter: { company: { eq: companyId } },
    sort: { createdAt: SortOptions.Desc },
  });
  const { handleDeleteUserFromCompany, loading } = useDeleteUserFromCompany(companyId);

  const UserComponent: React.FC<UserComponentProps> = ({ user: { _id, firstName, lastName, phone, email, role } }) => {
    return (
      <div className="bg-bg-light-gray2 w-[230px] h-[105px] rounded-[20px] flex flex-row p-5">
        <div className="flex flex-col basis-6/7">
          <span className="font-sf-pro-bold text-h4">{`${firstName} ${lastName}`}</span>
          <span className="font-sf-pro-medium text-h4 text-brand-primary mt-2.5">{phone}</span>
          <span className="font-sf-pro-regular text-text-secondary text-h6">{email}</span>
        </div>
        {role !== UserRoles.Admin ? (
          <div
            className="basis-1/7 flex justify-end cursor-pointer"
            onClick={() => {
              setUserToDeleteId(_id);
              setShowConfirm(true);
            }}
          >
            <div>
              <img src={DeleteIcon} />
            </div>
          </div>
        ) : undefined}
      </div>
    );
  };

  const renderRows = () => {
    if (!orders?.length) return null;
    return orders.map((order) => {
      return (
        <CompanyOrderTableRow
          data={order}
          key={order._id}
          onClick={() => navigate(getOrderDetailsPath({ orderId: order._id }))}
        />
      );
    });
  };

  const onDeleteUser = async () => {
    await handleDeleteUserFromCompany({ userId: userToDeleteId });
    setShowConfirm(false);
    setUserToDeleteId(null);
  };

  const onCloseModal = () => {
    setUserToDeleteId(null);
    setShowConfirm(false);
  };

  return (
    <>
      <Confirm show={showConfirm} onConfirm={onDeleteUser} onCancel={onCloseModal} loading={loading} />
      <div className="m-6 flex flex-col h-full space-y-6">
        <div className="h-10">
          <span className="font-sf-pro-regular text-h1 text-text-secondary mr-2.5">Firmy</span>
          <span className="font-sf-pro-regular text-h1 text-text-secondary">/</span>
          <span className="text-h1 ml-2.5 font-sf-pro-bold">Szczegóły</span>
        </div>
        <div className="h-full bg-white p-5 rounded-[20px]">
          <div className="flex flex-col space-y-2">
            <span className="font-sf-pro-bold text-h3">{company?.name}</span>
            <span className="font-sf-pro-regular text-h5 text-text-secondary">{company?.companyId}</span>
          </div>
          <div className="mt-12 grid grid-cols-5">
            <div className="flex flex-col space-y-2">
              <span className="text-text-secondary font-sf-pro-regular h3">Kontakt</span>
              <div className="flex flex-col">
                <span className="font-sf-pro-regular">{company?.email}</span>
                <span className="font-sf-pro-regular">{company?.phone}</span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="text-text-secondary font-sf-pro-regular h3">Adres</span>
              <div className="flex flex-col">
                <span className="font-sf-pro-regular">{`${company?.country} ${company?.postalCode} ${company?.city}`}</span>
                <span className="font-sf-pro-regular">{`${company?.address}`}</span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="text-text-secondary font-sf-pro-regular h3">Rejestracja</span>
              <span className="font-sf-pro-regular">{moment(company?.createdAt).format("DD-MM-YYYY HH:mm")}</span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="text-text-secondary font-sf-pro-regular h3">Preferowana strefa czasowa</span>
              <span className="font-sf-pro-regular">GMT+1</span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="text-text-secondary font-sf-pro-regular h3">Preferowana waluta</span>
              <span className="font-sf-pro-regular">PLN - Polski Złoty</span>
            </div>
          </div>
          <div className="mt-12 flex flex-row space-x-5">
            {company?.users?.map((user) => <UserComponent key={user?._id} user={user} />)}
          </div>
          <div className="mt-12 w-3/5">
            <div className="flex flex-col space-y-2">
              <span className="text-text-secondary font-sf-pro-regular h3">Historia zamówień</span>
              <Table headers={[]} rows={renderRows()} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
