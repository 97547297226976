import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

import { OrderStatus } from "src/api/graphql/types-and-hooks";

import { formatPrice } from "src/utils/currency";

import InvoiceIcon from "src/assets/images/invoice.svg";

import { useOrder, useUploadInvoiceToOrder } from "../order/order.hooks";
import { useDownloadInvoice } from "src/common/hooks/useDownloadInvoice";
import AppUploadInput from "src/components/form/AppUploadInput";

const OrderDetails = () => {
  const { orderId } = useParams();
  const { order } = useOrder(orderId);
  const { handleDownloadInvoice } = useDownloadInvoice();
  const { handleUploadInvoice, loading } = useUploadInvoiceToOrder(orderId);
  const fileName = order?.invoice?.split("/")[2] || "-";

  const Pill = (title: string, bgColor: string, titleColor: string) => {
    return (
      <div className={`px-5 py-2 h-[33px] rounded-[36px] ${bgColor} flex justify-center items-center`}>
        <span className={`${titleColor} text-h6`}> {title}</span>
      </div>
    );
  };

  return (
    <div className="m-6 flex flex-col h-full space-y-6">
      <div className="h-10">
        <span className="font-sf-pro-regular text-h1 text-text-secondary mr-2.5">Zamówienia</span>
        <span className="font-sf-pro-regular text-h1 text-text-secondary">/</span>
        <span className="text-h1 ml-2.5 font-sf-pro-bold">Szczegóły</span>
      </div>
      <div className="flex flex-row space-x-16">
        <div className="basis-1/3  w-full h-[185px] rounded-[20px] bg-white">
          <div className="flex flex-row h-full">
            <div className="relative basis-1/2 pt-5 pl-5">
              <div>
                <p className="font-sf-pro-bold text-h4">{order?.number}</p>
                <p className="font-sf-pro-medium text-h5 text-blue mt-2">{formatPrice(order?.price, "zł")}</p>
              </div>
              <div className="absolute bottom-5 left-5">
                {order?.status === OrderStatus.Completed
                  ? Pill("Zapłacone", "bg-light-green", "text-green")
                  : order?.status === OrderStatus.Pending || order?.status === OrderStatus.New
                    ? Pill("Przetwarzanie", "bg-light-blue", "text-blue")
                    : order?.status === OrderStatus.Cancelled
                      ? Pill("Anulowane", "bg-light-red", "text-red")
                      : undefined}
              </div>
            </div>
            <div className="relative basis-1/2">
              <div className="flex flex-col h-full">
                <div className="basis-1/3">
                  <p className="text-h6 font-sf-pro-medium text-text-secondary text-end pt-6 pr-4">
                    {order?.createdAt ? moment(order.createdAt).format("DD-MM-YYYY HH:mm") : "-"}
                  </p>
                </div>
                <div className="basis-2/3 w-full h-full bg-blue p-5 rounded-tl-[20px] rounded-br-[20px]">
                  <div className="absolute bottom-2 left-2 flex flex-col">
                    <span className="font-sf-pro-medium text-h3 text-white">{order?.subscription.name}</span>
                    <span className="font-sf-pro text-h6 text-white mt-1">
                      do {order?.subscriptionEndDate ? moment(order.subscriptionEndDate).format("DD-MM-YYYY") : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-2/3 mt-4 flex flex-row">
          <div>
            <p className="font-sf-pro-regular text-text-secondary">Zamawiający</p>
            <div className="flex flex-col mt-2">
              <p className="font-sf-pro-bold text-h2">{order?.company?.name}</p>
              <p className="font-sf-pro-regular text-text-secondary text-h5 mt-2">NIP: {order?.company?.companyId}</p>
              <p className="font-sf-pro-regular mt-2">{`${order?.company?.postalCode} ${order?.company?.city}`}</p>
              <p className="font-sf-pro-regular">{`${order?.company?.address}`}</p>
            </div>
          </div>
          <div className="flex items-center justify-center ml-12">
            <div className="bg-white w-[230px] h-[105px] rounded-[20px] flex flex-row p-5">
              <div className="flex flex-col basis-6/7">
                <span className="font-sf-pro-bold text-h4">
                  {order?.user ? `${order?.user?.firstName} ${order?.user?.lastName}` : "-"}
                </span>
                <span className="font-sf-pro-medium text-h4 text-brand-primary mt-2.5">
                  {order?.user?.phone || "-"}
                </span>
                <span className="font-sf-pro-regular text-text-secondary text-h6">{order?.user?.email || "-"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full rounded-[20px] bg-white p-5">
        <p className="font-sf-pro-regular text-text-secondary">Faktura</p>
        <div className="flex flex-row justify-between border-b border-border-primary pb-2 mt-2 max-w-[30%]">
          <p>{fileName}</p>
          {order?.invoice ? (
            <img
              className="cursor-pointer"
              src={InvoiceIcon}
              onClick={() => handleDownloadInvoice(orderId, fileName)}
            />
          ) : undefined}
        </div>
        <div className="mt-3 w-[130px]">
          <AppUploadInput
            customClass="w-full text-h6 h-[30px] rounded-[12px]"
            onClick={handleUploadInvoice}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
