import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

import { removeTypenameFromMutationLink } from "src/utils/omitTypenameField";

const APP_CLIENT_NAME = "admin";
// export const API_URL = "http://localhost:3001";
export const API_URL = "https://api.flike.uniquedevs.usermd.net";

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
  };
});
const uploadLink = createUploadLink({ uri: `${API_URL}/graphql`, headers: { "Apollo-Require-Preflight": "true" } });
const link = from([removeTypenameFromMutationLink, authLink, uploadLink]);

export const client = new ApolloClient({
  name: APP_CLIENT_NAME,
  link,
  cache: new InMemoryCache(),
});
