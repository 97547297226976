import React from "react";
import moment from "moment";

import TableRow from "src/components/ui/TableRow";
import { formatPrice } from "src/utils/currency";

type Props = {
  data: any;
  onClick: () => void;
};

const CompanyOrderTableRow: React.FC<Props> = ({
  data: {
    subscription: { name },
    price,
    number,
    subscriptionEndDate,
    subscriptionStartDate,
  },
  onClick,
}) => {
  const isSubscriptionActive = subscriptionEndDate ? moment(subscriptionEndDate).isAfter(moment()) : false;
  return (
    <TableRow onClick={onClick}>
      <td>
        <span className="ml-4 text-medium">{number}</span>
      </td>
      <td>
        <span className="ml-4 text-medium">{name}</span>
      </td>
      <td>
        <span className="ml-4 text-medium">{moment(subscriptionStartDate).format("DD-MM-YYYY HH:mm")}</span>
      </td>
      <td>
        <div
          className={`rounded-[36px] h-[30px] max-w-[107px] flex justify-center items-center ${
            isSubscriptionActive ? "bg-light-green" : "bg-light-red"
          }`}
        >
          <span className={`${isSubscriptionActive ? "text-green" : "text-red"} font-sf-pro-medium text-h5`}>
            {moment(subscriptionEndDate).format("DD-MM-YYYY")}
          </span>
        </div>
      </td>
      <td>
        <span className="ml-4 text-medium">{formatPrice(price, "zł")}</span>
      </td>
    </TableRow>
  );
};

export default CompanyOrderTableRow;
