import React from "react";
import { isEmpty } from "lodash";

// import { ThreeDots } from "react-loader-spinner";

type Props = {
  headers: string[];
  rows?: React.ReactNode[] | null;
  noDataMsg?: string;
  isLoading?: boolean;
};

const Table: React.FC<Props> = ({ headers, rows, noDataMsg, isLoading }) => {
  const renderHeaders = () => {
    return headers.map((header: string, i: number) => {
      const roundFirst = i === 0 ? "rounded-l-[8px]" : undefined;
      const roundLast = i === headers?.length - 1 ? "rounded-r-[8px]" : undefined;

      return (
        <th className={`${roundFirst} ${roundLast}`} key={header}>
          <span className={`font-sf-pro-medium text-h6 flex text-left ${i === 0 ? "ml-5" : undefined}`}>{header}</span>
        </th>
      );
    });
  };

  const renderRows = () => {
    // if (isLoading) {
    //   return (
    //     <tr>
    //       <td colSpan={headers?.length}>
    //         <ThreeDots
    //           ariaLabel="three-dots-loading"
    //           color="#4fa94d"
    //           height="80"
    //           radius="9"
    //           visible={isLoading}
    //           width="80"
    //           wrapperStyle={{ justifyContent: "center" }}
    //         />
    //       </td>
    //     </tr>
    //   );
    // }

    if (!rows || isEmpty(rows)) {
      return (
        <tr>
          <td className="border-0 text-center" colSpan={headers.length}>
            <div className="empty-list d-flex justify-content-center flex-column align-items-center">
              <p className="font-weight-light">{noDataMsg || "-"}</p>
            </div>
          </td>
        </tr>
      );
    }

    return rows.map((row: React.ReactNode) => {
      return row;
    });
  };

  return (
    <div className="m-5">
      <table className="w-full table-auto">
        {headers?.length ? (
          <thead className="bg-bg-light-gray2 h-[44px]">
            <tr>{renderHeaders()}</tr>
          </thead>
        ) : undefined}
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default Table;
