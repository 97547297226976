import React from "react";

type Props = {
  label: string;
  register: any;
  id: string;
};

const AppCheckbox: React.FC<Props> = ({ label, register, id }) => {
  return (
    <>
      <div className="flex flex-row space-x-5 items-center">
        <input
          {...register(id)}
          id={id}
          className="form-checkbox w-[18px] h-[18px] rounded-[4px] cursor-pointer checked:bg-blue ring-0 focus:ring-0 border border-[#DFE5F1]"
          type="checkbox"
        />
        <span className="font-sf-pro-medium text-h6 text-text-secondary">{label}</span>
      </div>
    </>
  );
};

export default AppCheckbox;
