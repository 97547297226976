import React from "react";
import moment from "moment";

import TableRow from "src/components/ui/TableRow";
import { formatPrice } from "src/utils/currency";

type Props = {
  data: any;
  onClick: () => void;
};

const CompanyTableRow: React.FC<Props> = ({
  data: { name, companyId, subscribedFrom, subscribedTo, subscription },
  onClick,
}) => {
  return (
    <TableRow onClick={onClick}>
      <td>
        <span className="ml-4 text-medium">{name}</span>
      </td>
      <td>
        <span className="font-sf-pro-medium text-h5 text-text-secondary">{companyId}</span>
      </td>
      <td>
        <span className="font-sf-pro-medium text-h5 text-text-secondary">
          {subscribedFrom ? moment(subscribedFrom).format("DD-MM-YYYY") : "Nieaktywna"}
        </span>
      </td>
      <td>
        <div
          className={`rounded-[36px] h-[30px] max-w-[107px] flex justify-center items-center ${
            subscribedTo ? "bg-light-green" : "bg-bg-light-gray2"
          }`}
        >
          {subscribedTo ? (
            <span className="text-green font-sf-pro-medium text-h5">{moment(subscribedTo).format("DD-MM-YYYY")}</span>
          ) : (
            <span className="font-sf-pro-medium text-h5 text-text-secondary">Nieaktywna</span>
          )}
        </div>
      </td>
      <td>
        <div>
          <span className="font-sf-pro-medium text-h5 text-text-secondary">
            {subscription ? `${formatPrice(subscription.price, "zł")}` : "0 zł"}
          </span>
        </div>
      </td>
    </TableRow>
  );
};

export default CompanyTableRow;
