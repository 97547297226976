import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AppDatePicker from "src/components/form/AppDatePicker";
import AppInput, { InputTypes } from "src/components/form/AppInput";

import FiltersWrapper from "src/components/ui/FiltersWrapper";
import userFiltersSchema from "./userFilters.schema";

type Props = {
  onChange: (data: any) => void;
};

const UserFilters: React.FC<Props> = ({ onChange }) => {
  const { control, register, handleSubmit, reset } = useForm({
    resolver: yupResolver(userFiltersSchema),
    reValidateMode: "onSubmit",
  });

  const parseData = (data: any) => {
    const { firstName, lastName, createdAtFrom, createdAtTo } = data;
    const filters = {};

    if (firstName) {
      filters["firstName"] = { regex: firstName };
    }
    if (lastName) {
      filters["lastName"] = { regex: lastName };
    }

    const createdAt = {};
    if (createdAtFrom || createdAtTo) {
      if (createdAtFrom) createdAt["gte"] = moment(createdAtFrom).unix();
      if (createdAtTo) createdAt["lte"] = moment(createdAtTo).unix();

      filters["createdAt"] = createdAt;
    }

    onChange(filters);
  };

  const resetForm = () => {
    reset({
      firstName: null,
      lastName: null,
      company: null,
      createdAtFrom: null,
      createdAtTo: null,
    });

    onChange({});
  };

  return (
    <FiltersWrapper>
      <div className="mt-7 mx-5">
        <div className="border-b">
          <div className="mt-3">
            <p className="text-h5 font-sf-pro-medium">Dane</p>
            <div className="mt-3">
              <AppInput
                id="firstName"
                inputType={InputTypes.TEXT}
                placeholder="Imię"
                register={register}
                inputClasses="font-sf-pro-medium h-12 border border-bg-light-gray3"
              />
            </div>
            <div className="mt-3">
              <AppInput
                id="lastName"
                inputType={InputTypes.TEXT}
                placeholder="Nazwisko"
                register={register}
                inputClasses="font-sf-pro-medium h-12 border border-bg-light-gray3"
              />
            </div>
            <div className="flex flex-row space-x-3 mb-7">
              <div className="mt-3 w-full">
                <Controller
                  control={control}
                  name="createdAtFrom"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data od" />}
                />
              </div>
              <div className="mt-3 w-full">
                <Controller
                  control={control}
                  name="createdAtTo"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data do" />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-7">
          <AppButton
            customClass="rounded-[10px] h-9"
            title="Filtruj"
            onClick={handleSubmit(parseData)}
            color={ButtonColorTypes.PRIMARY}
          />
        </div>
        <div className="mt-3 flex justify-center cursor-pointer" onClick={resetForm}>
          <p className="font-sf-pro-regular h-6 text-text-secondary">Wyczyść</p>
        </div>
      </div>
    </FiltersWrapper>
  );
};

export default UserFilters;
