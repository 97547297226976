import { toast } from "react-hot-toast";

enum ToastColor {
  success = "#61B449",
  error = "#FF5252",
}

export const useToastSuccess = (message = "Zapisano") =>
  toast.success(message, {
    duration: 3500,
    position: "bottom-right",
    style: { backgroundColor: ToastColor.success, minWidth: 200, maxWidth: 300, minHeight: 50, color: "white" },
  });

export const useToastError = (message = "Wystąpił błąd") =>
  toast.error(message, {
    duration: 3500,
    position: "bottom-right",
    style: { backgroundColor: ToastColor.error, minWidth: 200, maxWidth: 300, minHeight: 50, color: "white" },
  });
