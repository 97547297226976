import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Toaster } from "react-hot-toast";

import Root from "./Root";

// providers
import { client } from "./api/apollo/client";
import { AuthenticationProvider } from "./common/AuthProvider/AuthProvider";

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <Root />
        <Toaster />
      </AuthenticationProvider>
    </ApolloProvider>
  );
}

export default App;
