import React, { useState } from "react";

import Table from "src/components/ui/Table";
import Pagination from "src/components/ui/Pagination";
import ModuleHeader from "src/components/ui/ModuleHeader";
import ModuleContentContainer from "src/components/ui/ModuleContentContainer";

import { getSubscriptionNewPath, getSubscriptionsPath } from "src/common/router/routerPaths";

import SubscriptionTableRow from "./SubscriptionTableRow";
import { useSubscriptions } from "./subscription.hooks";
import { SortOptions } from "src/api/graphql/types-and-hooks";

const HEADERS = ["Nazwa", "Opis", "Cena", "Czas trwania", ""];

const Subscriptions = () => {
  const [filteringData, setFilteringData] = useState({ take: 5, skip: 0, sort: { createdAt: SortOptions.Desc } });
  const { subscriptions, total } = useSubscriptions(filteringData);

  const renderRows = () => {
    if (!subscriptions?.length) return null;
    return subscriptions.map((d: any) => {
      return <SubscriptionTableRow data={d} key={d._id} />;
    });
  };

  return (
    <>
      <ModuleHeader title="Subskrypcje" addRoute={getSubscriptionNewPath()} />
      <ModuleContentContainer title="Lista subskrypcji">
        <Table headers={HEADERS} isLoading={false} rows={renderRows()} />
        <Pagination
          total={total}
          filteringData={filteringData}
          route={getSubscriptionsPath()}
          onChange={setFilteringData}
        />
      </ModuleContentContainer>
    </>
  );
};

export default Subscriptions;
