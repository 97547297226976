import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddUserToCompanyInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AddUserToCompanyResponse = {
  _id: Scalars['ID']['output'];
};

export type BaseCreateResponse = {
  id: Scalars['ID']['output'];
};

export type BaseDeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type BaseDeleteResponse = {
  success: Scalars['Boolean']['output'];
};

export type CompaniesFilterOptionsInput = {
  companyId?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
  subscribedFrom?: InputMaybe<FilterOperators>;
  subscribedTo?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type CompaniesList = {
  documents: Array<Company>;
  total: Scalars['Float']['output'];
};

export type CompaniesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export type Company = {
  _id: Scalars['ID']['output'];
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  demoUsed: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  subscribedFrom?: Maybe<Scalars['DateTime']['output']>;
  subscribedTo?: Maybe<Scalars['DateTime']['output']>;
  subscription?: Maybe<Subscription>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users: Array<User>;
};

export type CreateCompanyInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type CreateInvoiceCategoryInput = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceInput = {
  billFrom: InvoiceCompanyInput;
  billTo: InvoiceCompanyInput;
  category: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  invoiceNumber: Scalars['String']['input'];
  items: Array<InvoiceItemInput>;
  paymentDue: Scalars['DateTime']['input'];
  paymentType: Scalars['String']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type CreateInvoiceResponse = {
  id: Scalars['ID']['output'];
};

export type CreateNotificationInput = {
  company: Scalars['String']['input'];
  description: Scalars['String']['input'];
  module: NotificationModuleTypes;
  recordId: Scalars['String']['input'];
};

export type CreateOrderInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type CreateOrderResponse = {
  id: Scalars['ID']['output'];
  paymentUrl: Scalars['String']['output'];
};

export type CreateSubscriptionInput = {
  description: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  isActive: Scalars['Boolean']['input'];
  isDemo: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type CreateSubscriptionResponse = {
  id: Scalars['ID']['output'];
};

export type DeleteUserFromCompanyInput = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type FilterOperators = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  success: Scalars['Boolean']['output'];
};

export type GetCompaniesOptionsInput = {
  filter?: InputMaybe<CompaniesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<CompaniesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetInvoiceCategoriesOptionsInput = {
  filter?: InputMaybe<InvoiceCategoriesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoiceCategoriesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetInvoicesOptionsInput = {
  filter?: InputMaybe<InvoicesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoicesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetNotificationsOptionsInput = {
  filter?: InputMaybe<NotificationsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<NotificationsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetOrdersOptionsInput = {
  filter?: InputMaybe<OrdersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<OrdersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetSubscriptionsOptionsInput = {
  filter?: InputMaybe<SubscriptionsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<SubscriptionsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetUsersOptionsInput = {
  filter?: InputMaybe<UsersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<UsersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type InovicesList = {
  documents: Array<Invoice>;
  total: Scalars['Float']['output'];
};

export type InviteUserToCompanyInput = {
  email: Scalars['String']['input'];
  role: UserRoles;
};

export type InviteUserToCompanyResponse = {
  success: Scalars['Boolean']['output'];
};

export type Invoice = {
  _id: Scalars['ID']['output'];
  billFrom: InvoiceCompany;
  billTo: InvoiceCompany;
  category: InvoiceCategory;
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['DateTime']['output'];
  invoiceNumber: Scalars['String']['output'];
  items: Array<InvoiceItem>;
  paymentDue: Scalars['DateTime']['output'];
  paymentType: Scalars['String']['output'];
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vat: Scalars['Float']['output'];
};

export type InvoiceCategoriesFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
};

export type InvoiceCategoriesList = {
  documents: Array<InvoiceCategory>;
  total: Scalars['Float']['output'];
};

export type InvoiceCategoriesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type InvoiceCategory = {
  _id: Scalars['ID']['output'];
  ancestors: Array<InvoiceCategory>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  isLeaf: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceCompany = {
  address: Scalars['String']['output'];
  bankAccountNumber: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type InvoiceCompanyInput = {
  address: Scalars['String']['input'];
  bankAccountNumber: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type InvoiceItem = {
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type InvoiceItemInput = {
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type InvoicesFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  invoiceNumber?: InputMaybe<FilterOperators>;
};

export type InvoicesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type Mutation = {
  addUserToCompany: AddUserToCompanyResponse;
  createCompany: Company;
  createInvoice: CreateInvoiceResponse;
  createInvoiceCategory: BaseCreateResponse;
  createNotification: BaseCreateResponse;
  createOrder: CreateOrderResponse;
  createSubscription: CreateSubscriptionResponse;
  deleteNotification: BaseDeleteResponse;
  deleteUserFromCompany: BaseDeleteResponse;
  forgotPassword: ForgotPasswordResponse;
  inviteUserToCompany: InviteUserToCompanyResponse;
  login: LoginResponse;
  loginAdmin: LoginResponse;
  refresh: LoginResponse;
  register: RegisterResponse;
  resetPassword: ResetPasswordResponse;
  updateCompany: Company;
  updateInvoice: Invoice;
  updateSubscription: Subscription;
  updateUser: User;
  uploadInvoice: UploadInvoiceResponse;
  uploadInvoiceToOrder: UploadInvoiceToOrderResponse;
  verifyAccount: VerifyAccountResponse;
};


export type MutationAddUserToCompanyArgs = {
  addUserToCompanyInput: AddUserToCompanyInput;
};


export type MutationCreateCompanyArgs = {
  createCompanyInput: CreateCompanyInput;
};


export type MutationCreateInvoiceArgs = {
  createInvoiceInput: CreateInvoiceInput;
};


export type MutationCreateInvoiceCategoryArgs = {
  createInvoiceCategoryInput: CreateInvoiceCategoryInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreateSubscriptionArgs = {
  CreateSubscriptionInput: CreateSubscriptionInput;
};


export type MutationDeleteNotificationArgs = {
  deleteNotificationInput: BaseDeleteDocumentInput;
};


export type MutationDeleteUserFromCompanyArgs = {
  deleteUserFromCompanyInput: DeleteUserFromCompanyInput;
};


export type MutationForgotPasswordArgs = {
  forgotPasswordInput: ForgotPasswordInput;
};


export type MutationInviteUserToCompanyArgs = {
  inviteUserToCompanyInput: InviteUserToCompanyInput;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationLoginAdminArgs = {
  loginInput: LoginInput;
};


export type MutationRegisterArgs = {
  registerInput: RegisterInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationUpdateCompanyArgs = {
  updateCompanyInput: UpdateCompanyInput;
};


export type MutationUpdateInvoiceArgs = {
  updateInvoiceInput: UpdateInvoiceInput;
};


export type MutationUpdateSubscriptionArgs = {
  updateSubscriptionInput: UpdateSubscriptionInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUploadInvoiceArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadInvoiceToOrderArgs = {
  uploadInoviceToOrderInput: UploadInoviceToOrderInput;
};


export type MutationVerifyAccountArgs = {
  verifyAccountInput: VerifyAccountInput;
};

export type Notification = {
  _id: Scalars['ID']['output'];
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  module: NotificationModuleTypes;
  recordId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export enum NotificationModuleTypes {
  Invoices = 'INVOICES'
}

export type NotificationsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
};

export type NotificationsList = {
  documents: Array<Notification>;
  total: Scalars['Float']['output'];
};

export type NotificationsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type Order = {
  _id: Scalars['ID']['output'];
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  invoice?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  payment: Payment;
  price: Scalars['Float']['output'];
  status: OrderStatus;
  subscription: Subscription;
  subscriptionEndDate: Scalars['DateTime']['output'];
  subscriptionStartDate: Scalars['DateTime']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type OrdersFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  number?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type OrdersList = {
  documents: Array<Order>;
  total: Scalars['Float']['output'];
};

export type OrdersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  number?: InputMaybe<SortOptions>;
};

export type Payment = {
  _id: Scalars['ID']['output'];
  company: Company;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foreignId: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  status: OrderStatus;
  subscription: Subscription;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type Query = {
  checkAuth: LoginResponse;
  companies: CompaniesList;
  company: Company;
  getInvoiceCategories: InvoiceCategoriesList;
  invoice: Invoice;
  invoices: InovicesList;
  notification: Notification;
  notifications: NotificationsList;
  order: Order;
  orders: OrdersList;
  subscription: Subscription;
  subscriptions: SubscriptionsList;
  user: User;
  users: UsersList;
};


export type QueryCompaniesArgs = {
  options: GetCompaniesOptionsInput;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInvoiceCategoriesArgs = {
  options: GetInvoiceCategoriesOptionsInput;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  options: GetInvoicesOptionsInput;
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  options: GetNotificationsOptionsInput;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  options: GetOrdersOptionsInput;
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionsArgs = {
  options: GetSubscriptionsOptionsInput;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  options: GetUsersOptionsInput;
};

export type RegisterInput = {
  companyAddress: Scalars['String']['input'];
  companyCity: Scalars['String']['input'];
  companyEmail: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyPhone: Scalars['String']['input'];
  companyPostalCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type RegisterResponse = {
  _id: Scalars['ID']['output'];
};

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  success: Scalars['Boolean']['output'];
};

export enum SortOptions {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  isActive: Scalars['Boolean']['output'];
  isDemo: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
};

export type SubscriptionsList = {
  documents: Array<Subscription>;
  total: Scalars['Float']['output'];
};

export type SubscriptionsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export type UpdateCompanyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  demoUsed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subscribedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  subscribedTo?: InputMaybe<Scalars['DateTime']['input']>;
  subscription?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateInvoiceInput = {
  billFrom: InvoiceCompanyInput;
  billTo: InvoiceCompanyInput;
  category: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  items: Array<InvoiceItemInput>;
  paymentDue: Scalars['DateTime']['input'];
  paymentType: Scalars['String']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type UpdateSubscriptionInput = {
  _id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  isActive: Scalars['Boolean']['input'];
  isDemo: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadInoviceToOrderInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['String']['input'];
};

export type UploadInvoiceResponse = {
  _id: Scalars['ID']['output'];
};

export type UploadInvoiceToOrderResponse = {
  _id: Scalars['ID']['output'];
};

export type User = {
  _id: Scalars['ID']['output'];
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  orders?: Maybe<Array<Order>>;
  phone: Scalars['Float']['output'];
  role: UserRoles;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum UserRoles {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UsersFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  firstName?: InputMaybe<FilterOperators>;
  lastName?: InputMaybe<FilterOperators>;
};

export type UsersList = {
  documents: Array<User>;
  total: Scalars['Float']['output'];
};

export type UsersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  firstName?: InputMaybe<SortOptions>;
};

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
};

export type VerifyAccountResponse = {
  success: Scalars['Boolean']['output'];
};
