import React from "react";
import { UseFormRegister } from "react-hook-form";

import ErrorIcon from "src/assets/images/error.svg";

type Props = {
  id: string;
  register: UseFormRegister<any>;
  error?: boolean;
  placeholder?: string;
  cols?: number;
  rows?: number;
};

const AppTextArea: React.FC<Props> = ({ id, register, error, placeholder, cols, rows }) => {
  return (
    <div className="relative">
      <textarea
        {...register(id)}
        className={`w-full border rounded-[10px] bg-transparent font-sf-pro-regular px-5 py-3 text-h5 placeholder-text-secondary focus:border-[#233145] focus:outline-none ${
          error && "border-state-error focus:border-state-error"
        }`}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
      />
      {error && (
        <div className="absolute inset-y-0 right-5 flex items-center">
          <img role="presentation" src={ErrorIcon} />
        </div>
      )}
    </div>
  );
};

export default AppTextArea;
