import React from "react";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

const AppHeader = () => {
  const user = useCurrentUser();

  return (
    <div className="h-[91px] border-b-[1.5px] border-light-gray-2 flex justify-end items-center">
      <div className="mr-12 flex flex-row gap-x-4">
        <div className="rounded-full bg-gray-200 w-[42px] h-[42px] flex justify-center items-center font-sf-pro-bold text-h4">
          {`${user?.firstName[0]} ${user?.lastName[0]}`}
        </div>
        <div className="flex flex-col justify-center">
          <span className="font-sf-pro-bold text-h4">{`${user?.firstName} ${user?.lastName}`}</span>
          <span className="text-xs font-sf-pro-regular text-text-secondary">{user?.email}</span>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
