import React from "react";
import moment from "moment";

import TableRow from "src/components/ui/TableRow";
import { OrderStatus } from "src/api/graphql/types-and-hooks";

type Props = {
  data: any;
  onClick: () => void;
};

const OrderTableRow: React.FC<Props> = ({
  data: {
    number,
    company,
    createdAt,
    status,
    subscription: { name },
  },
  onClick,
}) => {
  const Pill = (title: string, bgColor: string, titleColor: string) => {
    return (
      <div className={`h-[33px] rounded-[36px] ${bgColor} flex justify-center items-center`}>
        <span className={`${titleColor} text-h6`}> {title}</span>
      </div>
    );
  };

  return (
    <TableRow onClick={onClick}>
      <td>
        <span className="ml-4 text-medium">{number}</span>
      </td>
      <td>
        <span className="font-sf-pro-medium text-h5 text-text-secondary">{company.name}</span>
      </td>
      <td>
        <span className="font-sf-pro-medium text-h5 text-text-secondary">
          {createdAt ? moment(createdAt).format("DD-MM-YYYY HH:mm") : "-"}
        </span>
      </td>
      <td>{name}</td>
      <td>
        <div>
          {status === OrderStatus.Completed
            ? Pill("Zapłacone", "bg-light-green", "text-green")
            : status === OrderStatus.Pending || status === OrderStatus.New
              ? Pill("Przetwarzanie", "bg-light-blue", "text-blue")
              : status === OrderStatus.Cancelled
                ? Pill("Anulowane", "bg-light-red", "text-red")
                : undefined}
        </div>
      </td>
    </TableRow>
  );
};

export default OrderTableRow;
