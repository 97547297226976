import { date, mixed, object, string } from "yup";

export default object({
  companyId: string().nullable(),
  number: string().nullable(),
  subscription: mixed().nullable(),
  status: string().nullable(),
  createdAtFrom: date().nullable(),
  createdAtTo: date().nullable(),
  priceFrom: string().nullable(),
  priceTo: string().nullable(),
}).required();
