import React from "react";
import { Outlet } from "react-router-dom";
import AppHeader from "src/components/ui/AppHeader";
import Sidebar from "src/components/ui/Sidebar";

const HomeWrapper = () => {
  return (
    <>
      <div className="flex flex-row">
        <div className="basis-1/7">
          <Sidebar />
        </div>
        <div className="basis-6/7">
          <AppHeader />
          <div className="bg-bg-light-gray flex flex-col h-[calc(100%-91px)]">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeWrapper;
