import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AppButton, { ButtonColorTypes } from "../form/AppButton";

type Props = {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
};

const Confirm: React.FC<Props> = ({ show, onConfirm, onCancel, loading }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative bg-gradient-to-r to-[#202B4F] from-[#352837] transform overflow-hidden rounded-[42px] text-left shadow-xl transition-all px-12 py-10 w-[574px]">
                <div className="text-left">
                  <Dialog.Title as="h3" className="text-white font-sf-pro-medium text-[42px]">
                    Uwaga
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-text-secondary text-h5">Czy na pewno chcesz usunąć rekord?</p>
                  </div>
                </div>
                <div className="mt-11 flex flex-row space-x-4">
                  <div className="basis-1/2">
                    <AppButton
                      title="Potwierdź"
                      color={ButtonColorTypes.PRIMARY}
                      onClick={onConfirm}
                      customClass="rounded-[12px] h-10"
                      isLoading={loading}
                    />
                  </div>
                  <div className="basis-1/2">
                    <AppButton
                      title="Anuluj"
                      color={ButtonColorTypes.SECONDARY}
                      onClick={onCancel}
                      customClass="rounded-[12px] h-10 border border-light-gray"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Confirm;
