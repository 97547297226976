import React from "react";
import Datepicker, { DateRangeType } from "react-tailwindcss-datepicker";

type Props = {
  error?: string;
  field: any;
  placeholder?: string;
};

const AppDatePicker: React.FC<Props> = ({ field, placeholder }) => {
  return (
    <Datepicker
      value={{ startDate: field.value, endDate: field.value }}
      onChange={(date: DateRangeType) => field.onChange(date?.startDate)}
      primaryColor={"sky"}
      placeholder={placeholder}
      asSingle
      containerClassName={
        "px-5 border border-bg-light-gray3 h-[46px] rounded-[10px] relative flex justify-space-between hover:border-[#233145]"
      }
      inputClassName={"text-h6 font-sf-pro-regular text-left w-full focus:ring-0 focus:outline-none focus:border-none"}
    />
  );
};

export default AppDatePicker;
