import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppTextArea from "src/components/form/AppTextarea";
import AppCheckbox from "src/components/form/AppCheckbox";
import AppInput, { InputTypes } from "src/components/form/AppInput";

import subscriptionSchema from "./subscription.schema";

type Props = {
  formValues?: any;
  formRef: any;
  onSubmit: (values: any) => Promise<void> | void;
};

const SubscriptionForm: React.FC<Props> = ({ formValues, formRef, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    defaultValues: formValues || {},
    resolver: yupResolver(subscriptionSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    reset(formValues);
  }, [reset, formValues]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <div className="h-full bg-white p-5 rounded-[20px]">
        <div className="grid grid-cols-2 w-6/12 gap-4">
          <AppInput
            id="name"
            inputType={InputTypes.TEXT}
            placeholder="Nazwa"
            register={register}
            inputClasses="font-sf-pro-medium h-12 border border-bg-light-gray3"
            error={!!formErrors.name}
          />
          <AppInput
            id="price"
            inputType={InputTypes.TEXT}
            placeholder="Cena"
            register={register}
            inputClasses="font-sf-pro-medium h-12 border border-bg-light-gray3"
            error={!!formErrors.price}
          />
        </div>
        <div className="grid grid-cols-2 w-6/12 gap-4 mt-5">
          <AppTextArea
            id="description"
            register={register}
            placeholder="Opis"
            rows={5}
            error={!!formErrors.description}
          />
          <div>
            <AppInput
              id="duration"
              inputType={InputTypes.TEXT}
              placeholder="Czas trwania (dni)"
              register={register}
              inputClasses="font-sf-pro-medium h-12 border border-bg-light-gray3"
              error={!!formErrors.duration}
            />
            <div className="mt-6 flex flex-col space-y-2">
              <AppCheckbox id="isDemo" register={register} label="Subskrypcja demo" />
              <AppCheckbox id="isActive" register={register} label="Subskrypcja aktywna" />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SubscriptionForm;
