import { useLoginAdminMutation } from "src/api/graphql/generated/gql-hooks";
import { LoginInput } from "src/api/graphql/types-and-hooks";
import { useSetCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useLogin = () => {
  const setCurrentUser = useSetCurrentUser();
  const [login, { loading }] = useLoginAdminMutation({
    onError: () => useToastError("Niepoprawne dane logowania"),
    onCompleted: (data) => {
      setCurrentUser(data.loginAdmin);
      useToastSuccess("Zalogowano");
    },
  });

  return {
    handleLogin: async (input: LoginInput) => {
      await login({
        variables: {
          loginInput: {
            email: input.email,
            password: input.password,
          },
        },
      });
    },
    loading,
  };
};
