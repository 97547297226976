import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useLogout } from "src/common/AuthProvider/authProvider.hooks";
import { getCompaniesPath, getOrdersPath, getSubscriptionsPath, getUsersPath } from "src/common/router/routerPaths";

import MenuSubsIcon from "src/assets/images/menu-subscriptions.svg";
import MenuSubsWhiteIcon from "src/assets/images/menu-subscriptions-white.svg";
import MenuUsersIcon from "src/assets/images/menu-users.svg";
import MenuUsersWhiteIcon from "src/assets/images/menu-users-white.svg";
import MenuOrdersIcon from "src/assets/images/menu-orders.svg";
import MenuOrdersWhiteIcon from "src/assets/images/menu-orders-white.svg";
import MenuCompaniesIcon from "src/assets/images/menu-companies.svg";
import MenuCompaniesWhiteIcon from "src/assets/images/menu-companies-white.svg";
import MenuLogoutIcon from "src/assets/images/logout.svg";
import SidebarLogoImage from "src/assets/images/sidebar-logo.png";

type MenuLinkProps = {
  title: string;
  icon: any;
  activeIcon?: any;
  route?: string;
  isRouteActive?: boolean;
};

const MenuLink: React.FC<MenuLinkProps> = ({ title, icon, activeIcon, route, isRouteActive }) => {
  const location = useLocation();

  if (isRouteActive) {
    return (
      <Link to={route}>
        <div className="flex flex-row justify-between items-center ml-3.5 px-3.5 h-[44px] mr-6 bg-brand-primary rounded-[10px] border-none outline-none">
          <h2 className="font-sf-pro-medium text-h5 text-text-white">{title}</h2>
          <img src={activeIcon} />
        </div>
      </Link>
    );
  }

  return (
    <Link to={route}>
      <div className="flex flex-row ml-6 mr-12 gap-x-3.5">
        <img src={icon} />
        <h2 className="font-sf-pro-regular text-h5 text-dark-blue">{title}</h2>
      </div>
    </Link>
  );
};

const Sidebar = () => {
  const logout = useLogout();
  const location = useLocation();

  return (
    <div className="h-screen border-r-[1.5px] border-light-gray-2">
      <div className="h-[91px] border-b-[1.5px] border-light-gray-2 flex items-center">
        <div className="ml-6">
          <img src={SidebarLogoImage} className="w-[135px]" />
        </div>
      </div>
      <div className="mt-7 mb-7 flex flex-col gap-y-7">
        <MenuLink
          title="Firmy"
          icon={MenuCompaniesIcon}
          activeIcon={MenuCompaniesWhiteIcon}
          route={getCompaniesPath()}
          isRouteActive={location.pathname.includes("/companies") || location.pathname.includes("/company")}
        />
        <MenuLink
          title="Użytkownicy"
          icon={MenuUsersIcon}
          activeIcon={MenuUsersWhiteIcon}
          route={getUsersPath()}
          isRouteActive={location.pathname.includes("/user")}
        />
        <MenuLink
          title="Subskrypcje"
          icon={MenuSubsIcon}
          activeIcon={MenuSubsWhiteIcon}
          route={getSubscriptionsPath()}
          isRouteActive={location.pathname.includes("/subscription")}
        />
        <MenuLink
          title="Zamówienia"
          icon={MenuOrdersIcon}
          activeIcon={MenuOrdersWhiteIcon}
          route={getOrdersPath()}
          isRouteActive={location.pathname.includes("/order")}
        />
      </div>
      <div className="border-b-[1.5px] border-light-gray-2 ml-6 mr-12"></div>
      <div className="mt-7 flex flex-col gap-y-7">
        <div onClick={logout}>
          <MenuLink title="Wyloguj" icon={MenuLogoutIcon} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
