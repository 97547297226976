import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  filters?: ReactNode;
  title: string;
};

const ModuleContentContainer: React.FC<Props> = ({ children, filters, title }) => {
  return (
    <div className="m-6 flex flex-row h-full space-x-6">
      {filters ? <div className="basis-2/7">{filters}</div> : undefined}
      <div className={`${filters ? "basis-5/7" : "basis-7/7 w-full"}`}>
        <div className="h-full bg-white rounded-[20px] pl-6 pt-6">
          <span className="font-sf-pro-bold text-h3">{title}</span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModuleContentContainer;
