import { date, mixed, object, string } from "yup";

export default object({
  subscription: mixed().nullable(),
  name: string().nullable(),
  companyId: string().nullable(),
  createdAtFrom: date().nullable(),
  createdAtTo: date().nullable(),
  subscribedFromFrom: date().nullable(),
  subscribedFromTo: date().nullable(),
  subscribedToFrom: date().nullable(),
  subscribedToTo: date().nullable(),
}).required();
