import React from "react";

export enum ButtonColorTypes {
  PRIMARY = "brand-primary",
  SECONDARY = "",
}

type Props = {
  color: ButtonColorTypes;
  title: string;
  customClass?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: string;
};

const spinner = () => (
  <svg className="animate-spin mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const AppButton: React.FC<Props> = ({ color, title, customClass = "", onClick, disabled, isLoading, icon }) => {
  return (
    <button
      className={`w-full text-h3 font-sf-pro-medium bg-${color} text-text-white flex flex-row justify-center items-center ${customClass}`}
      disabled={disabled || isLoading}
      onClick={onClick}
      type="button"
    >
      {!isLoading && icon ? <img className="mr-3" src={icon} /> : undefined}
      {isLoading ? spinner() : undefined}
      {title}
    </button>
  );
};

export default AppButton;
