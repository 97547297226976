import React, { useEffect, useState } from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import AppSelect from "src/components/form/AppSelect";
import AppDatePicker from "src/components/form/AppDatePicker";
import FiltersWrapper from "src/components/ui/FiltersWrapper";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import orderFiltersSchema from "./orderFilters.schema";
import { useSubscriptions } from "../subscription/subscription.hooks";

type Props = {
  onChange: (data: any) => void;
};

const OrderFilters: React.FC<Props> = ({ onChange }) => {
  const [subscriptionOptions, setSubscriptionOptions] = useState<{ value: string; label: string }[]>([]);
  const { subscriptions } = useSubscriptions({ take: 20, skip: 0 });

  useEffect(() => {
    if (subscriptions?.length) {
      setSubscriptionOptions(subscriptions.map((s) => ({ value: s._id, label: s.name })));
    }
  }, [subscriptions]);

  const { control, register, handleSubmit, reset } = useForm({
    resolver: yupResolver(orderFiltersSchema),
    reValidateMode: "onSubmit",
  });

  const parseData = (data: any) => {
    const { number, subscription, createdAtFrom, createdAtTo } = data;
    const filters = {};

    if (subscription) {
      filters["subscription"] = { eq: subscription.value };
    }

    if (number) {
      filters["number"] = { regex: number };
    }

    const createdAt = {};
    if (createdAtFrom || createdAtTo) {
      if (createdAtFrom) createdAt["gte"] = moment(createdAtFrom).unix();
      if (createdAtTo) createdAt["lte"] = moment(createdAtTo).unix();

      filters["createdAt"] = createdAt;
    }

    onChange(filters);
  };

  const resetForm = () => {
    reset({
      number: null,
      subscription: null,
      createdAtFrom: null,
      createdAtTo: null,
    });

    onChange({});
  };

  return (
    <FiltersWrapper>
      <div className="mt-7 mx-5">
        <div className="border-b">
          <div className="mt-3">
            <p className="text-h5 font-sf-pro-medium">Dane</p>
            <div className="mt-3">
              <AppInput
                id="number"
                inputType={InputTypes.TEXT}
                placeholder="Numer"
                register={register}
                inputClasses="font-sf-pro-medium h-12 border border-bg-light-gray3"
              />
            </div>
            <div className="flex flex-row space-x-3">
              <div className="mt-3 w-full">
                <Controller
                  control={control}
                  name="subscription"
                  render={({ field }) => (
                    <AppSelect options={subscriptionOptions} field={field} placeholder="Nazwa subskrypcji" />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-row space-x-3 mb-7">
              <div className="mt-3 w-full">
                <Controller
                  control={control}
                  name="createdAtFrom"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data od" />}
                />
              </div>
              <div className="mt-3 w-full">
                <Controller
                  control={control}
                  name="createdAtTo"
                  render={({ field }) => <AppDatePicker field={field} placeholder="Data do" />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-7">
          <AppButton
            customClass="rounded-[10px] h-9"
            title="Filtruj"
            onClick={handleSubmit(parseData)}
            color={ButtonColorTypes.PRIMARY}
          />
        </div>
        <div className="mt-3 flex justify-center cursor-pointer" onClick={resetForm}>
          <p className="font-sf-pro-regular h-6 text-text-secondary">Wyczyść</p>
        </div>
      </div>
    </FiltersWrapper>
  );
};

export default OrderFilters;
