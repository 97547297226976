import { useNavigate } from "react-router-dom";

import {
  useCreateSubscriptionMutation,
  useSubscriptionQuery,
  useSubscriptionsQuery,
  useUpdateSubscriptionMutation,
} from "src/api/graphql/generated/gql-hooks";
import {
  CreateSubscriptionInput,
  GetSubscriptionsOptionsInput,
  UpdateSubscriptionInput,
} from "src/api/graphql/types-and-hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";
import { getSubscriptionsPath } from "src/common/router/routerPaths";

export const useSubscriptions = (options: GetSubscriptionsOptionsInput) => {
  const { loading, data } = useSubscriptionsQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const subscriptions = data?.subscriptions?.documents || [];
  const total = data?.subscriptions?.total;

  return {
    loading,
    subscriptions,
    total,
  };
};

export const useCreateSubscription = () => {
  const navigate = useNavigate();
  const [createSubscription, { loading }] = useCreateSubscriptionMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano");
      navigate(getSubscriptionsPath());
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    loading,
    createSubscription: async (createSubscriptionInput: CreateSubscriptionInput) => {
      await createSubscription({
        variables: {
          createSubscriptionInput,
        },
      });
    },
  };
};

const useSubscription = (subscriptionId: string) => {
  const { loading, data } = useSubscriptionQuery({
    variables: { subscriptionId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const subscription = data?.subscription;

  return {
    loading,
    subscription,
  };
};

export const useEditSubscription = (subscriptionId: string) => {
  const navigate = useNavigate();
  const { loading, subscription } = useSubscription(subscriptionId);
  const [editSubscription, { loading: isUpdating }] = useUpdateSubscriptionMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano");
      navigate(getSubscriptionsPath());
    },
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    editSubscription: async (updateSubscriptionInput: UpdateSubscriptionInput) => {
      await editSubscription({
        variables: {
          updateSubscriptionInput,
        },
      });
    },
    loading,
    subscription,
    isUpdating,
  };
};
