import React, { useState } from "react";
import { UseFormRegister } from "react-hook-form";

import ErrorIcon from "src/assets/images/error.svg";

export enum InputTypes {
  TEXT = "text",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
}

type Props = {
  id: string;
  inputType: InputTypes;
  register: UseFormRegister<any>;
  error?: boolean;
  placeholder?: string;
  disabled?: boolean;
  iconEnd?: string;
  inputClasses?: string;
};

const AppInput: React.FC<Props> = ({
  id,
  inputType,
  register,
  error,
  placeholder,
  disabled,
  iconEnd,
  inputClasses,
}) => {
  const [type, setType] = useState(inputType);

  const showValue = () => {
    if (type === InputTypes.PASSWORD) {
      setType(InputTypes.TEXT);
    } else {
      setType(InputTypes.PASSWORD);
    }
  };

  return (
    <div className="relative">
      <input
        {...register(id)}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        className={`w-full rounded-[10px] bg-transparent font-sf-pro-regular pl-5 text-h5 placeholder-text-secondary border focus:border-[#233145] focus:outline-none ${inputClasses} ${
          error && "border-state-error focus:border-state-error"
        }`}
      />
      {iconEnd && !error ? (
        <div className="absolute inset-y-0 right-5 flex items-center cursor-pointer">
          <img onClick={showValue} role="presentation" src={iconEnd} />
        </div>
      ) : undefined}
      {error ? (
        <div className="absolute inset-y-0 right-5 flex items-center">
          <img onClick={showValue} role="presentation" src={ErrorIcon} />
        </div>
      ) : undefined}
    </div>
  );
};

export default AppInput;
