import React from "react";

import UploadIcon from "src/assets/images/upload.svg";

import AppButton, { ButtonColorTypes } from "./AppButton";

type Props = {
  customClass?: string;
  onClick: (file: File) => void;
  loading?: boolean;
};

const AppUploadInput: React.FC<Props> = ({ customClass, onClick, loading }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        accept="application/pdf"
        type="file"
        required
        className="hidden"
        onChange={({
          target: {
            validity,
            files: [file],
          },
        }) => {
          if (validity.valid) {
            onClick(file);
          }
        }}
      />
      <AppButton
        icon={UploadIcon}
        title="Prześlij plik"
        onClick={() => inputRef.current?.click()}
        color={ButtonColorTypes.PRIMARY}
        customClass={customClass}
        isLoading={loading}
      />
    </>
  );
};
export default AppUploadInput;
