import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddUserToCompanyInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type BaseDeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type CompaniesFilterOptionsInput = {
  companyId?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
  subscribedFrom?: InputMaybe<FilterOperators>;
  subscribedTo?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type CompaniesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export type CreateCompanyInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type CreateInvoiceCategoryInput = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceInput = {
  billFrom: InvoiceCompanyInput;
  billTo: InvoiceCompanyInput;
  category: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  invoiceNumber: Scalars['String']['input'];
  items: Array<InvoiceItemInput>;
  paymentDue: Scalars['DateTime']['input'];
  paymentType: Scalars['String']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type CreateNotificationInput = {
  company: Scalars['String']['input'];
  description: Scalars['String']['input'];
  module: NotificationModuleTypes;
  recordId: Scalars['String']['input'];
};

export type CreateOrderInput = {
  subscriptionId: Scalars['ID']['input'];
};

export type CreateSubscriptionInput = {
  description: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  isActive: Scalars['Boolean']['input'];
  isDemo: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type DeleteUserFromCompanyInput = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type FilterOperators = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type GetCompaniesOptionsInput = {
  filter?: InputMaybe<CompaniesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<CompaniesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetInvoiceCategoriesOptionsInput = {
  filter?: InputMaybe<InvoiceCategoriesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoiceCategoriesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetInvoicesOptionsInput = {
  filter?: InputMaybe<InvoicesFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<InvoicesSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetNotificationsOptionsInput = {
  filter?: InputMaybe<NotificationsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<NotificationsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetOrdersOptionsInput = {
  filter?: InputMaybe<OrdersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<OrdersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetSubscriptionsOptionsInput = {
  filter?: InputMaybe<SubscriptionsFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<SubscriptionsSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type GetUsersOptionsInput = {
  filter?: InputMaybe<UsersFilterOptionsInput>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<UsersSortOptionsInput>;
  take?: Scalars['Int']['input'];
};

export type InviteUserToCompanyInput = {
  email: Scalars['String']['input'];
  role: UserRoles;
};

export type InvoiceCategoriesFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
};

export type InvoiceCategoriesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type InvoiceCompanyInput = {
  address: Scalars['String']['input'];
  bankAccountNumber: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type InvoiceItemInput = {
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type InvoicesFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  invoiceNumber?: InputMaybe<FilterOperators>;
};

export type InvoicesSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum NotificationModuleTypes {
  Invoices = 'INVOICES'
}

export type NotificationsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
};

export type NotificationsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type OrdersFilterOptionsInput = {
  company?: InputMaybe<FilterOperators>;
  createdAt?: InputMaybe<FilterOperators>;
  number?: InputMaybe<FilterOperators>;
  subscription?: InputMaybe<FilterOperators>;
};

export type OrdersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  number?: InputMaybe<SortOptions>;
};

export type RegisterInput = {
  companyAddress: Scalars['String']['input'];
  companyCity: Scalars['String']['input'];
  companyEmail: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyPhone: Scalars['String']['input'];
  companyPostalCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export enum SortOptions {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SubscriptionsFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  name?: InputMaybe<FilterOperators>;
};

export type SubscriptionsSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  name?: InputMaybe<SortOptions>;
};

export type UpdateCompanyInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  demoUsed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subscribedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  subscribedTo?: InputMaybe<Scalars['DateTime']['input']>;
  subscription?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateInvoiceInput = {
  billFrom: InvoiceCompanyInput;
  billTo: InvoiceCompanyInput;
  category: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  items: Array<InvoiceItemInput>;
  paymentDue: Scalars['DateTime']['input'];
  paymentType: Scalars['String']['input'];
  subtotal: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
  vat: Scalars['Float']['input'];
};

export type UpdateSubscriptionInput = {
  _id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  isActive: Scalars['Boolean']['input'];
  isDemo: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadInoviceToOrderInput = {
  file: Scalars['Upload']['input'];
  id: Scalars['String']['input'];
};

export enum UserRoles {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UsersFilterOptionsInput = {
  createdAt?: InputMaybe<FilterOperators>;
  firstName?: InputMaybe<FilterOperators>;
  lastName?: InputMaybe<FilterOperators>;
};

export type UsersSortOptionsInput = {
  createdAt?: InputMaybe<SortOptions>;
  firstName?: InputMaybe<SortOptions>;
};

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
};

export type CreateSubscriptionMutationVariables = Exact<{
  createSubscriptionInput: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'CreateSubscriptionResponse', id: string } };

export type DeleteUserFromCompanyMutationVariables = Exact<{
  deleteUserFromCompanyInput: DeleteUserFromCompanyInput;
}>;


export type DeleteUserFromCompanyMutation = { __typename?: 'Mutation', deleteUserFromCompany: { __typename?: 'BaseDeleteResponse', success: boolean } };

export type LoginAdminMutationVariables = Exact<{
  loginInput: LoginInput;
}>;


export type LoginAdminMutation = { __typename?: 'Mutation', loginAdmin: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', _id: string, email: string, firstName: string, lastName: string, phone: number } } };

export type UpdateSubscriptionMutationVariables = Exact<{
  updateSubscriptionInput: UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'Subscription', _id: string } };

export type UploadInvoiceToOrderMutationVariables = Exact<{
  uploadInoviceToOrderInput: UploadInoviceToOrderInput;
}>;


export type UploadInvoiceToOrderMutation = { __typename?: 'Mutation', uploadInvoiceToOrder: { __typename?: 'UploadInvoiceToOrderResponse', _id: string } };

export type CheckAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAuthQuery = { __typename?: 'Query', checkAuth: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, phone: number } } };

export type CompaniesQueryVariables = Exact<{
  options: GetCompaniesOptionsInput;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'CompaniesList', total: number, documents: Array<{ __typename?: 'Company', _id: string, name: string, companyId: string, subscribedFrom?: any | null, subscribedTo?: any | null, subscription?: { __typename?: 'Subscription', price: number } | null }> } };

export type CompanyQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', name: string, companyId: string, phone: string, email: string, address: string, city: string, country: string, postalCode: string, createdAt?: any | null, users: Array<{ __typename?: 'User', _id: string, firstName: string, lastName: string, phone: number, email: string, role: UserRoles }> } };

export type OrderQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', subscriptionEndDate: any, createdAt?: any | null, number: string, price: number, status: OrderStatus, invoice?: string | null, company: { __typename?: 'Company', address: string, city: string, companyId: string, email: string, name: string, phone: string, postalCode: string }, user: { __typename?: 'User', email: string, firstName: string, lastName: string, phone: number }, subscription: { __typename?: 'Subscription', _id: string, createdAt?: any | null, description: string, duration: number, isActive: boolean, isDemo: boolean, name: string, price: number, updatedAt?: any | null } } };

export type OrdersQueryVariables = Exact<{
  options: GetOrdersOptionsInput;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrdersList', total: number, documents: Array<{ __typename?: 'Order', _id: string, createdAt?: any | null, number: string, price: number, company: { __typename?: 'Company', name: string }, subscription: { __typename?: 'Subscription', name: string } }> } };

export type SubscriptionQueryVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;


export type SubscriptionQuery = { __typename?: 'Query', subscription: { __typename?: 'Subscription', _id: string, description: string, duration: number, isActive: boolean, isDemo: boolean, name: string, price: number } };

export type SubscriptionsQueryVariables = Exact<{
  options: GetSubscriptionsOptionsInput;
}>;


export type SubscriptionsQuery = { __typename?: 'Query', subscriptions: { __typename?: 'SubscriptionsList', total: number, documents: Array<{ __typename?: 'Subscription', _id: string, name: string, description: string, price: number, duration: number, isDemo: boolean, isActive: boolean }> } };

export type UsersQueryVariables = Exact<{
  options: GetUsersOptionsInput;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersList', total: number, documents: Array<{ __typename?: 'User', _id: string, firstName: string, lastName: string, email: string, phone: number, createdAt?: any | null, company?: { __typename?: 'Company', name: string } | null }> } };


export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($createSubscriptionInput: CreateSubscriptionInput!) {
  createSubscription(CreateSubscriptionInput: $createSubscriptionInput) {
    id
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      createSubscriptionInput: // value for 'createSubscriptionInput'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const DeleteUserFromCompanyDocument = gql`
    mutation DeleteUserFromCompany($deleteUserFromCompanyInput: DeleteUserFromCompanyInput!) {
  deleteUserFromCompany(deleteUserFromCompanyInput: $deleteUserFromCompanyInput) {
    success
  }
}
    `;
export type DeleteUserFromCompanyMutationFn = Apollo.MutationFunction<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>;

/**
 * __useDeleteUserFromCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromCompanyMutation, { data, loading, error }] = useDeleteUserFromCompanyMutation({
 *   variables: {
 *      deleteUserFromCompanyInput: // value for 'deleteUserFromCompanyInput'
 *   },
 * });
 */
export function useDeleteUserFromCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>(DeleteUserFromCompanyDocument, options);
      }
export type DeleteUserFromCompanyMutationHookResult = ReturnType<typeof useDeleteUserFromCompanyMutation>;
export type DeleteUserFromCompanyMutationResult = Apollo.MutationResult<DeleteUserFromCompanyMutation>;
export type DeleteUserFromCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteUserFromCompanyMutation, DeleteUserFromCompanyMutationVariables>;
export const LoginAdminDocument = gql`
    mutation LoginAdmin($loginInput: LoginInput!) {
  loginAdmin(loginInput: $loginInput) {
    user {
      _id
      email
      firstName
      lastName
      phone
    }
    accessToken
  }
}
    `;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($updateSubscriptionInput: UpdateSubscriptionInput!) {
  updateSubscription(updateSubscriptionInput: $updateSubscriptionInput) {
    _id
  }
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      updateSubscriptionInput: // value for 'updateSubscriptionInput'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const UploadInvoiceToOrderDocument = gql`
    mutation UploadInvoiceToOrder($uploadInoviceToOrderInput: UploadInoviceToOrderInput!) {
  uploadInvoiceToOrder(uploadInoviceToOrderInput: $uploadInoviceToOrderInput) {
    _id
  }
}
    `;
export type UploadInvoiceToOrderMutationFn = Apollo.MutationFunction<UploadInvoiceToOrderMutation, UploadInvoiceToOrderMutationVariables>;

/**
 * __useUploadInvoiceToOrderMutation__
 *
 * To run a mutation, you first call `useUploadInvoiceToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInvoiceToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInvoiceToOrderMutation, { data, loading, error }] = useUploadInvoiceToOrderMutation({
 *   variables: {
 *      uploadInoviceToOrderInput: // value for 'uploadInoviceToOrderInput'
 *   },
 * });
 */
export function useUploadInvoiceToOrderMutation(baseOptions?: Apollo.MutationHookOptions<UploadInvoiceToOrderMutation, UploadInvoiceToOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadInvoiceToOrderMutation, UploadInvoiceToOrderMutationVariables>(UploadInvoiceToOrderDocument, options);
      }
export type UploadInvoiceToOrderMutationHookResult = ReturnType<typeof useUploadInvoiceToOrderMutation>;
export type UploadInvoiceToOrderMutationResult = Apollo.MutationResult<UploadInvoiceToOrderMutation>;
export type UploadInvoiceToOrderMutationOptions = Apollo.BaseMutationOptions<UploadInvoiceToOrderMutation, UploadInvoiceToOrderMutationVariables>;
export const CheckAuthDocument = gql`
    query CheckAuth {
  checkAuth {
    user {
      _id
      firstName
      lastName
      email
      phone
    }
    accessToken
  }
}
    `;

/**
 * __useCheckAuthQuery__
 *
 * To run a query within a React component, call `useCheckAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuthQuery(baseOptions?: Apollo.QueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
      }
export function useCheckAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
        }
export function useCheckAuthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
        }
export type CheckAuthQueryHookResult = ReturnType<typeof useCheckAuthQuery>;
export type CheckAuthLazyQueryHookResult = ReturnType<typeof useCheckAuthLazyQuery>;
export type CheckAuthSuspenseQueryHookResult = ReturnType<typeof useCheckAuthSuspenseQuery>;
export type CheckAuthQueryResult = Apollo.QueryResult<CheckAuthQuery, CheckAuthQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($options: GetCompaniesOptionsInput!) {
  companies(options: $options) {
    documents {
      _id
      name
      companyId
      subscribedFrom
      subscribedTo
      subscription {
        price
      }
    }
    total
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export function useCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesSuspenseQueryHookResult = ReturnType<typeof useCompaniesSuspenseQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($companyId: ID!) {
  company(id: $companyId) {
    name
    companyId
    phone
    email
    address
    city
    country
    postalCode
    createdAt
    users {
      _id
      firstName
      lastName
      phone
      email
      role
    }
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export function useCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanySuspenseQueryHookResult = ReturnType<typeof useCompanySuspenseQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const OrderDocument = gql`
    query Order($orderId: ID!) {
  order(id: $orderId) {
    company {
      address
      city
      companyId
      email
      name
      phone
      postalCode
    }
    subscriptionEndDate
    createdAt
    number
    price
    status
    invoice
    user {
      email
      firstName
      lastName
      phone
    }
    subscription {
      _id
      createdAt
      description
      duration
      isActive
      isDemo
      name
      price
      updatedAt
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export function useOrderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<typeof useOrderSuspenseQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrdersDocument = gql`
    query Orders($options: GetOrdersOptionsInput!) {
  orders(options: $options) {
    documents {
      _id
      company {
        name
      }
      createdAt
      number
      subscription {
        name
      }
      price
    }
    total
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export function useOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const SubscriptionDocument = gql`
    query Subscription($subscriptionId: ID!) {
  subscription(id: $subscriptionId) {
    _id
    description
    duration
    isActive
    isDemo
    name
    price
  }
}
    `;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
      }
export function useSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export function useSubscriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionSuspenseQueryHookResult = ReturnType<typeof useSubscriptionSuspenseQuery>;
export type SubscriptionQueryResult = Apollo.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;
export const SubscriptionsDocument = gql`
    query Subscriptions($options: GetSubscriptionsOptionsInput!) {
  subscriptions(options: $options) {
    documents {
      _id
      name
      description
      price
      duration
      isDemo
      isActive
    }
    total
  }
}
    `;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export function useSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsSuspenseQueryHookResult = ReturnType<typeof useSubscriptionsSuspenseQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const UsersDocument = gql`
    query Users($options: GetUsersOptionsInput!) {
  users(options: $options) {
    documents {
      _id
      firstName
      lastName
      company {
        name
      }
      email
      phone
      createdAt
    }
    total
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;