import React from "react";
import moment from "moment";

import TableRow from "src/components/ui/TableRow";

type Props = {
  data: any;
};

const UserTableRow: React.FC<Props> = ({ data: { firstName, lastName, company, createdAt, phone, email } }) => {
  return (
    <TableRow>
      <td>
        <span className="ml-4 text-medium">
          {firstName} {lastName}
        </span>
      </td>
      <td>
        <span className="text-medium">{company?.name}</span>
      </td>
      <td>
        <span className="font-sf-pro-medium text-h5 text-text-secondary">{moment(createdAt).format("DD-MM-YYYY")}</span>
      </td>
      <td>
        <div className="flex flex-col">
          <span className="font-sf-pro-display text-h5 text-text-primary">{phone}</span>
          <span className="font-sf-pro-display text-h6 text-text-secondary">{email}</span>
        </div>
      </td>
    </TableRow>
  );
};

export default UserTableRow;
