import React, { createContext, ReactNode, useMemo, useState } from "react";
import { useCheckAuthQuery } from "src/api/graphql/generated/gql-hooks";

export type LoggedUser = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: number;
};

type Props = {
  children: ReactNode;
};

type AuthenticationContext = {
  user: LoggedUser | null;
  setUser: React.Dispatch<React.SetStateAction<LoggedUser | null>>;
};

const initialState = {
  user: null,
  setUser: () => null,
};

export const AuthenticationContext = createContext<AuthenticationContext>(initialState);

export const AuthenticationProvider = ({ children }: Props) => {
  const [user, setUser] = useState<LoggedUser | null>(null);

  const { loading } = useCheckAuthQuery({
    onError: () => localStorage.removeItem("accessToken"),
    onCompleted: (data) => {
      localStorage.setItem("accessToken", data.checkAuth.accessToken);
      setUser(data.checkAuth.user);
    },
  });

  const authenticationValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );

  //   @TODO add loading spinner
  if (loading) return <div>Loading...</div>;

  return <AuthenticationContext.Provider value={authenticationValue}>{children}</AuthenticationContext.Provider>;
};
