import axios from "axios";

import { API_URL } from "src/api/apollo/client";

export const useDownloadInvoice = () => {
  const accessToken = localStorage.getItem("accessToken");
  const downloadInvoice = async (id: string, fileName: string) => {
    const response = await axios.get(`${API_URL}/order/download/${id}/`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };
  return { handleDownloadInvoice: downloadInvoice };
};
