import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { range } from "lodash";

import PreviousIcon from "src/assets/images/pagination-arrow-left.svg";
import NextIcon from "src/assets/images/pagination-arrow-right.svg";

type Props = {
  total: number;
  filteringData: any;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  take?: number;
  route: string;
};

const Pagination: React.FC<Props> = ({ filteringData, total, take = 5, onChange, route }) => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [skip, setSkip] = useState<number>(0);

  const currentPage = page ? Number(page) : 1;
  const currentSkip = currentPage * take - take;
  const totalPages = Math.ceil(total / take);

  useEffect(() => {
    if (currentPage < 1 || currentPage > totalPages) {
      return navigate(route);
    }
  }, [currentPage, navigate, totalPages, route]);

  useEffect(() => {
    if (currentPage && currentSkip !== skip) {
      setSkip(currentSkip);

      const filterDataTmp = { ...filteringData };
      filterDataTmp.skip = currentSkip;
      filterDataTmp.take = take;
      onChange(filterDataTmp);
    }
  }, [filteringData, currentPage, currentSkip, skip, take, onChange]);

  const renderPaginationPages = (pages: number[]) => {
    return (
      <>
        {pages.map((paginationPage: number, index: number) => {
          return (
            <li
              className={`w-[28px] flex justify-center ${
                currentPage === paginationPage ? "rounded-[8px] bg-bg-light-gray2" : ""
              }`}
              key={`pagination-page-${index}`}
            >
              <Link to={`${route}/${paginationPage}`}>{paginationPage}</Link>
            </li>
          );
        })}
      </>
    );
  };

  const generateLeftPages = () => {
    let pages = range(1, 6);

    if (totalPages < 8) {
      pages = range(1, 8);
    }

    pages = pages.filter((pageNumber: number) => pageNumber <= totalPages);

    if (currentPage > 4 && totalPages >= 8) {
      pages.splice(1, 4);
    }

    return (
      <>
        {renderPaginationPages(pages)}
        {totalPages >= 8 && <li>...</li>}
      </>
    );
  };

  const generateCenterPages = () => {
    if (totalPages <= 8 || currentPage < 5 || (currentPage > totalPages - 4 && currentPage > 5)) {
      return null;
    }

    const pages = [currentPage - 1, currentPage, currentPage + 1];

    return (
      <>
        {renderPaginationPages(pages)}
        <li>...</li>
      </>
    );
  };

  const generateRightPages = () => {
    if (totalPages < 8) {
      return null;
    }

    let pages = range(totalPages - 4, totalPages + 1);
    pages = pages.filter((pageNumber: number) => pageNumber <= totalPages);

    if (currentPage < totalPages - 3) {
      pages.splice(0, 4);
    }

    return renderPaginationPages(pages);
  };

  const leftArrow = () => {
    if (!totalPages || (total && currentPage === 1)) {
      return null;
    }

    return (
      <li>
        <Link to={`${route}/${currentPage - 1}`}>
          <img src={PreviousIcon} />
        </Link>
      </li>
    );
  };

  const rightArrow = () => {
    if (!totalPages || (total && currentPage >= totalPages)) {
      return null;
    }

    return (
      <li>
        <Link to={`${route}/${currentPage + 1}`}>
          <img src={NextIcon} />
        </Link>
      </li>
    );
  };

  return (
    <div className="flex flex-row justify-center items-center h-[20px]">
      <nav>
        <ul className="inline-flex space-x-5 items-center">
          {leftArrow()}
          {generateLeftPages()}
          {generateCenterPages()}
          {generateRightPages()}
          {rightArrow()}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
