import React from "react";
import { useNavigate } from "react-router-dom";

import AppButton, { ButtonColorTypes } from "../form/AppButton";

type Props = {
  title: string;
  addRoute?: string;
};

const ModuleHeader: React.FC<Props> = ({ title, addRoute }) => {
  const navigate = useNavigate();

  return (
    <div className="mx-6 mt-6 h-[40px] flex flex-row justify-between">
      <span className="text-h1 font-sf-pro-bold">{title}</span>
      {addRoute ? (
        <div className="w-[112px]">
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            title="Dodaj"
            customClass="rounded-[8px] h-[40px] text-h5"
            onClick={() => navigate(addRoute)}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default ModuleHeader;
