import React, { useState } from "react";

import ModuleContentContainer from "src/components/ui/ModuleContentContainer";
import ModuleHeader from "src/components/ui/ModuleHeader";
import Table from "src/components/ui/Table";
import Pagination from "src/components/ui/Pagination";
import { useUsers } from "./user.hooks";
import { GetUsersOptionsInput } from "src/api/graphql/types-and-hooks";
import UserTableRow from "./UserTableRow";
import { getUsersPath } from "src/common/router/routerPaths";
import UserFilters from "./UserFilters";

const HEADERS = ["Imię i nazwisko", "Podmiot", "Data rejestracji", "Kontakt"];

const Users = () => {
  const [filteringData, setFilteringData] = useState<GetUsersOptionsInput>({ take: 5, skip: 0, filter: {} });
  const { users, total } = useUsers(filteringData);

  const renderRows = () => {
    if (!users) return null;
    return users.map((d) => {
      return <UserTableRow data={d} key={d._id} />;
    });
  };

  return (
    <>
      <ModuleHeader title="Użytkownicy" />
      <ModuleContentContainer
        filters={
          <UserFilters
            onChange={(filters) =>
              setFilteringData((prevState) => {
                return { ...prevState, filter: filters };
              })
            }
          />
        }
        title="Lista użytkowników"
      >
        <Table headers={HEADERS} isLoading={false} rows={renderRows()} />
        <Pagination total={total} filteringData={filteringData} route={getUsersPath()} onChange={setFilteringData} />
      </ModuleContentContainer>
    </>
  );
};

export default Users;
