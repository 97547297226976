import {
  useCompaniesQuery,
  useCompanyQuery,
  useDeleteUserFromCompanyMutation,
} from "src/api/graphql/generated/gql-hooks";
import { GetCompaniesOptionsInput } from "src/api/graphql/types-and-hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useCompanies = (options: GetCompaniesOptionsInput) => {
  const { loading, data } = useCompaniesQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const companies = data?.companies?.documents || [];
  const total = data?.companies?.total;

  return {
    loading,
    companies,
    total,
  };
};

export const useCompany = (companyId: string) => {
  const { loading, data } = useCompanyQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const company = data?.company;

  return {
    loading,
    company,
  };
};

export const useDeleteUserFromCompany = (companyId: string) => {
  const [deleteUserFromCompany, { loading }] = useDeleteUserFromCompanyMutation({
    onCompleted: () => {
      useToastSuccess("Użytkownik został usunięty");
    },
    onError: () => useToastError("Wystąpił błąd"),
    refetchQueries: () => ["Company"],
    awaitRefetchQueries: true,
  });

  return {
    handleDeleteUserFromCompany: async (input: { userId: string }) => {
      await deleteUserFromCompany({ variables: { deleteUserFromCompanyInput: { ...input, companyId } } });
    },
    loading,
  };
};
