export const getLoginPath = () => "/login";

export const getCompaniesPath = () => "/companies";
export const getCompanyDetailsPath = ({ companyId = ":companyId" } = {}) => `/companyDetails/${companyId}`;

export const getUsersPath = () => "/users";

export const getOrdersPath = () => "/orders";
export const getOrderDetailsPath = ({ orderId = ":orderId" } = {}) => `/orderDetails/${orderId}`;

export const getSubscriptionsPath = () => "/subscriptions";
export const getSubscriptionNewPath = () => "/subscription/new";
export const getSubscriptionEditPath = ({ subscriptionId = ":subscriptionId" } = {}) =>
  `/subscription/${subscriptionId}`;
