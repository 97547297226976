import React from "react";
import Select from "react-select";

type Props = {
  error?: string;
  field: any;
  placeholder?: string;
  options: { value: string; label: string }[];
};

const AppSelect: React.FC<Props> = ({ error, field, options = [], placeholder }) => {
  return (
    <div>
      <Select
        value={options.find((option) => option.value === field?.value?.value) || ""}
        onChange={(value) => field.onChange(value)}
        options={options}
        placeholder={placeholder}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: "#8E98A7",
            primary: "#233145",
          },
        })}
        components={{
          IndicatorSeparator: () => null,
        }}
        classNames={{
          valueContainer: () => "text-h5 font-sf-pro-regular",
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: "44px",
            border: "1px solid #DFE5F1",
            borderRadius: "10px",
            paddingLeft: "10px",
            "&:hover": {
              outline: "none",
            },
          }),
        }}
      />
    </div>
  );
};

export default AppSelect;
