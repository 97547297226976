import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { getCompaniesPath } from "src/common/router/routerPaths";
import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

import LoginImage from "src/assets/images/login.png";
import LogoImage from "src/assets/images/logo.png";
import EyeIcon from "src/assets/images/eye.svg";

import LoginSchema from "./login.schema";
import { useLogin } from "./login.hooks";

const Login = () => {
  const isLogged = useCurrentUser();
  const navigate = useNavigate();
  const { handleLogin, loading } = useLogin();
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(LoginSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isLogged) {
      return navigate(getCompaniesPath());
    }
  }, [isLogged]);

  return (
    <div className="grid grid-cols-5">
      <div className="col-span-3 bg-gradient-to-r from-bg-secondary to-bg-primary h-screen flex flex-col justify-center items-center">
        <img src={LogoImage} className="w-[106px]" />
        <p className="text-h1 mt-18 mb-12 text-white font-sf-pro-medium">Witaj</p>
        <div className="max-w-[50%] w-full">
          <AppInput
            id="email"
            inputType={InputTypes.TEXT}
            placeholder="Adres e-mail"
            register={register}
            inputClasses="font-sf-pro-medium h-12 border-white/20 focus:border-white/60 text-white"
          />
        </div>
        <div className="mt-13 max-w-[50%] w-full">
          <AppInput
            iconEnd={EyeIcon}
            id="password"
            inputType={InputTypes.PASSWORD}
            placeholder="Hasło"
            register={register}
            inputClasses="font-sf-pro-medium h-12 border-white/20 focus:border-white/60 text-white"
          />
        </div>
        <div className="mt-18 max-w-[50%] w-full">
          <AppButton
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-14"
            onClick={handleSubmit(handleLogin)}
            title="Zaloguj się"
            isLoading={loading}
          />
        </div>
      </div>
      <div className="col-span-2 bg-brand-primary flex-flex-row">
        <div className="flex flex-col h-screen">
          <div className="basis-3/4 flex justify-end items-end">
            <div>
              <img src={LoginImage} className="w-[594px]" />
            </div>
          </div>
          <div className="basis-1/4">
            <p className="text-white font-medium text-40 text-center mt-4 font-sf-pro-medium">Zapanuj nad</p>
            <p className="text-white font-medium text-40 text-center mt-2 font-sf-pro-medium">płynnością finansową</p>
            <p className="text-white font-medium text-40 text-center mt-2 font-sf-pro-medium">
              z <span className="text-brand-secondary">flike</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
