import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import {
  getCompaniesPath,
  getCompanyDetailsPath,
  getLoginPath,
  getOrdersPath,
  getSubscriptionsPath,
  getUsersPath,
  getSubscriptionNewPath,
  getSubscriptionEditPath,
  getOrderDetailsPath,
} from "./common/router/routerPaths";

import Users from "./pages/user/Users";
import Login from "./pages/login/Login";
import Orders from "./pages/order/Orders";
import Companies from "./pages/company/Companies";
import HomeWrapper from "./pages/home/Home";
import Subscriptions from "./pages/subscription/Subscriptions";
import CompanyDetails from "./pages/company/CompanyDetails";
import SubscriptionAdd from "./pages/subscription/SubscriptionAdd";
import SubscriptionEdit from "./pages/subscription/SubscriptionEdit";
import OrderDetails from "./pages/order/OrderDetails";

function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={getCompaniesPath()} />} />
        <Route element={<Login />} path={getLoginPath()} />
        <Route element={<HomeWrapper />}>
          <Route
            element={
              <RequireAuth>
                <Companies />
              </RequireAuth>
            }
            path={getCompaniesPath()}
          />
          <Route
            element={
              <RequireAuth>
                <Companies />
              </RequireAuth>
            }
            path={`${getCompaniesPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <CompanyDetails />
              </RequireAuth>
            }
            path={getCompanyDetailsPath()}
          />
          <Route
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
            path={getUsersPath()}
          />
          <Route
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
            path={`${getUsersPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <Subscriptions />
              </RequireAuth>
            }
            path={getSubscriptionsPath()}
          />
          <Route
            element={
              <RequireAuth>
                <Subscriptions />
              </RequireAuth>
            }
            path={`${getSubscriptionsPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <SubscriptionAdd />
              </RequireAuth>
            }
            path={getSubscriptionNewPath()}
          />
          <Route
            element={
              <RequireAuth>
                <SubscriptionEdit />
              </RequireAuth>
            }
            path={getSubscriptionEditPath()}
          />
          <Route
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
            path={getOrdersPath()}
          />
          <Route
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
            path={`${getOrdersPath()}/:page`}
          />
          <Route
            element={
              <RequireAuth>
                <OrderDetails />
              </RequireAuth>
            }
            path={getOrderDetailsPath()}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const isLoggedUser = localStorage.getItem("accessToken");

  if (!isLoggedUser) {
    return <Navigate to={getLoginPath()} replace />;
  }

  return children;
}

export default Root;
