import { GetOrdersOptionsInput } from "src/api/graphql/types-and-hooks";
import { useOrderQuery, useOrdersQuery, useUploadInvoiceToOrderMutation } from "src/api/graphql/generated/gql-hooks";

import { useToastError, useToastSuccess } from "src/common/hooks/useToast";

export const useOrders = (options: GetOrdersOptionsInput) => {
  const { loading, data } = useOrdersQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const orders = data?.orders?.documents || [];
  const total = data?.orders?.total;

  return {
    loading,
    orders,
    total,
  };
};

export const useOrder = (orderId: string) => {
  const { loading, data } = useOrderQuery({
    variables: { orderId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const order = data?.order;

  return {
    loading,
    order,
  };
};

export const useUploadInvoiceToOrder = (orderId: string) => {
  const [uploadInvoice, { loading }] = useUploadInvoiceToOrderMutation({
    onError: () => useToastError("Wystąpił błąd"),
    onCompleted: () => useToastSuccess("Plik został dodany"),
    refetchQueries: () => ["Order"],
    awaitRefetchQueries: true,
  });

  return {
    handleUploadInvoice: async (file: File) => {
      await uploadInvoice({
        variables: { uploadInoviceToOrderInput: { id: orderId, file } },
      });
    },
    loading,
  };
};
