import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { useEditSubscription } from "./subscription.hooks";
import SubscriptionForm from "./SubscriptionForm";

const SubscriptionEdit = () => {
  const submitRef = useRef<HTMLFormElement>();
  const { subscriptionId } = useParams();
  const { subscription, isUpdating, editSubscription } = useEditSubscription(subscriptionId);

  return (
    <>
      <div className="m-6 flex flex-col h-full space-y-6">
        <div className="flex flex-row justify-between">
          <div className="h-10">
            <span className="font-sf-pro-regular text-h1 text-text-secondary mr-2.5">Subskrypcje</span>
            <span className="font-sf-pro-regular text-h1 text-text-secondary">/</span>
            <span className="text-h1 ml-2.5 font-sf-pro-bold">Edytuj subskrypcję</span>
          </div>
          <div className="w-[112px]">
            <AppButton
              color={ButtonColorTypes.PRIMARY}
              title="Zapisz"
              customClass="rounded-[8px] h-[40px] text-h5"
              onClick={() => submitRef.current.requestSubmit()}
              isLoading={isUpdating}
            />
          </div>
        </div>
        <SubscriptionForm formValues={subscription} formRef={submitRef} onSubmit={editSubscription} />
      </div>
    </>
  );
};

export default SubscriptionEdit;
