import React, { useState } from "react";

import ModuleContentContainer from "src/components/ui/ModuleContentContainer";
import ModuleHeader from "src/components/ui/ModuleHeader";

import OrderFilters from "./OrderFilters";
import { GetOrdersOptionsInput } from "src/api/graphql/types-and-hooks";
import Table from "src/components/ui/Table";
import Pagination from "src/components/ui/Pagination";
import { getOrderDetailsPath, getOrdersPath } from "src/common/router/routerPaths";
import { useOrders } from "./order.hooks";
import OrderTableRow from "./OrderTableRow";
import { useNavigate } from "react-router-dom";

const HEADERS = ["Numer zamówienia", "Podmiot", "Data zakupu", "Subskrypcja", ""];

const Orders = () => {
  const navigate = useNavigate();
  const [filteringData, setFilteringData] = useState<GetOrdersOptionsInput>({ take: 5, skip: 0, filter: {} });
  const { orders, total } = useOrders(filteringData);

  const renderRows = () => {
    if (!orders) return null;
    return orders.map((d) => {
      return <OrderTableRow data={d} key={d._id} onClick={() => navigate(getOrderDetailsPath({ orderId: d._id }))} />;
    });
  };

  return (
    <>
      <ModuleHeader title="Zamówienia" />
      <ModuleContentContainer
        filters={
          <OrderFilters
            onChange={(filters) =>
              setFilteringData((prevState) => {
                return { ...prevState, filter: filters };
              })
            }
          />
        }
        title="Lista zamówień"
      >
        <Table headers={HEADERS} isLoading={false} rows={renderRows()} />
        <Pagination total={total} filteringData={filteringData} route={getOrdersPath()} onChange={setFilteringData} />
      </ModuleContentContainer>
    </>
  );
};

export default Orders;
